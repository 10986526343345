var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "header-container" },
        [
          _vm._t("prepend"),
          _vm._v(" "),
          _c("h2", {
            staticClass: "modal-header__title",
            domProps: { textContent: _vm._s(_vm.title) }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("Fab", {
        attrs: { "data-cy": "modal-close", theme: _vm.secondaryTheme },
        nativeOn: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.onClose.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var color = ref.color
              return [_c("IconClose", { attrs: { "fill-color": color } })]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }