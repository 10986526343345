var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "NuxtLink",
        {
          staticClass: "navigation-side-bar-element__link",
          attrs: {
            to: { name: _vm.route.name },
            "aria-label": "Navigation side bar element"
          }
        },
        [
          _c(
            "div",
            { staticClass: "navigation-side-bar-element__icon" },
            [
              _c(_vm.listIcon, {
                tag: "Component",
                attrs: { "fill-color": _vm.listIconFillColor }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FadeNavigationSideBarTextTransition",
            [
              _c("TranslatableTitleProvider", {
                attrs: { title: _vm.route.meta.title },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var translatableTitle = ref.translatableTitle
                      return [
                        _vm.isExpanded
                          ? _c("span", {
                              staticClass: "navigation-side-bar-element__title",
                              domProps: {
                                textContent: _vm._s(translatableTitle)
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }