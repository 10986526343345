var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FadeTransition", [
    _vm.isMounted
      ? _c(
          "div",
          {
            ref: "modalOverlay",
            staticClass: "modal-overlay",
            attrs: { "data-cy": "modal-overlay" },
            on: { mousedown: _vm.onMouseDown, mouseup: _vm.onMouseUp }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }