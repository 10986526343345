var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { class: _vm.classes },
    [
      _c(
        "ol",
        { staticClass: "navigation-side-bar__menu" },
        [
          _c(
            "li",
            [
              _c(
                "NuxtLink",
                {
                  attrs: { "aria-label": "Menu list element", to: "/dashboard" }
                },
                [
                  _c("NavigationSideBarLogo", {
                    attrs: { "is-expanded": _vm.isExpanded }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.routes, function(route, index) {
            return [
              route.group
                ? _c("NavigationSideBarGroup", {
                    key: index,
                    attrs: {
                      route: route,
                      "children-queries": _vm.childrenQueries,
                      "is-expanded": _vm.isExpanded,
                      "is-selected": _vm.isGroupSelected(route.group.title)
                    },
                    on: { select: _vm.onGroupSelect }
                  })
                : _c("NavigationSideBarElement", {
                    key: index,
                    attrs: {
                      route: _vm.getRoute(route),
                      "is-expanded": _vm.isExpanded
                    }
                  })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("Fab", {
        attrs: {
          floating: { bottom: "16px", left: "24px" },
          theme: _vm.secondaryTheme
        },
        nativeOn: {
          click: function($event) {
            return _vm.onExpand.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function(ref) {
              var isHovered = ref.isHovered
              var color = ref.color
              return [
                _c("IconArrowDouble", {
                  attrs: {
                    "fill-color": isHovered ? color : _vm.whiteColor,
                    state: _vm.expendStateIcon
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }