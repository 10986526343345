var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalOverlay",
    { on: { close: _vm.onClose } },
    [
      _c(
        "Modal",
        { attrs: { "data-cy": "modal" } },
        [
          _c("ModalHeader", {
            attrs: { title: _vm.title },
            on: { close: _vm.onClose },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function() {
                    return [_vm._t("prependHeader")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("ModalBody", [_vm._t("body")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }