import { render, staticRenderFns } from "./NavigationSideBarElement.vue?vue&type=template&id=7bf446bc&scoped=true&"
import script from "./NavigationSideBarElement.vue?vue&type=script&lang=js&"
export * from "./NavigationSideBarElement.vue?vue&type=script&lang=js&"
import style0 from "./NavigationSideBarElement.vue?vue&type=style&index=0&id=7bf446bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf446bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ergonode/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bf446bc')) {
      api.createRecord('7bf446bc', component.options)
    } else {
      api.reload('7bf446bc', component.options)
    }
    module.hot.accept("./NavigationSideBarElement.vue?vue&type=template&id=7bf446bc&scoped=true&", function () {
      api.rerender('7bf446bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/NavigationSideBar/NavigationSideBarElement.vue"
export default component.exports