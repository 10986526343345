var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      class: _vm.classes,
      on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave }
    },
    [
      _c(
        "div",
        {
          staticClass: "navigation-side-bar-group__activator",
          on: { click: _vm.onGroupSelect }
        },
        [
          _c(_vm.listIcon, {
            tag: "Component",
            staticClass: "navigation-side-bar-group__icon",
            attrs: { "fill-color": _vm.listIconFillColor }
          }),
          _vm._v(" "),
          _c(
            "FadeNavigationSideBarTextTransition",
            [
              _c("TranslatableTitleProvider", {
                attrs: { title: _vm.route.group.title },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var translatableTitle = ref.translatableTitle
                      return [
                        _vm.isExpanded
                          ? _c("span", {
                              staticClass: "navigation-side-bar-group__title",
                              domProps: {
                                textContent: _vm._s(translatableTitle)
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isExpanded
            ? _c("IconArrowDropdown", {
                attrs: {
                  state: _vm.dropDownState,
                  "fill-color": _vm.dropdownIconFillColor
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.isExpanded
        ? [
            _c("AutoHeightTransition", [
              _vm.isSelected
                ? _c(
                    "ul",
                    { staticClass: "navigation-side-bar-group__items" },
                    _vm._l(_vm.route.routes, function(child, index) {
                      return _c("NavigationSideBarGroupElement", {
                        key: index,
                        attrs: {
                          "is-expanded": !_vm.isExpanded && _vm.isHovered,
                          route: _vm.getRoute(child)
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ])
          ]
        : _vm.isHovered
        ? _c(
            "ul",
            { staticClass: "navigation-side-bar-group__items" },
            [
              _c(
                "li",
                { staticClass: "navigation-side-bar-group__expanded-title" },
                [
                  _c("TranslatableTitleProvider", {
                    attrs: { title: _vm.route.group.title },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var translatableTitle = ref.translatableTitle
                          return [
                            _c("span", {
                              staticClass: "navigation-side-bar-group__title",
                              domProps: {
                                textContent: _vm._s(translatableTitle)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.route.routes, function(child, index) {
                return _c("NavigationSideBarGroupElement", {
                  key: index,
                  attrs: {
                    route: _vm.getRoute(child),
                    "is-expanded": _vm.isHovered
                  }
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "navigation-side-bar-group__footer" })
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }